/* Mass-Driver 2021 */
/* CSS Example for MD IO 0.4 */

@font-face {
    font-family: 'MD IO';
    src: url('./WOFF/MDIO0.4-Regular.woff2') format('woff2'), url('./WOFF/MDIO0.4-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MD IO';
    src: url('./WOFF/MDIO0.4-Italic.woff2') format('woff2'), url('./WOFF/MDIO0.4-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'MD IO';
    src: url('./WOFF/MDIO0.4-Medium.woff2') format('woff2'), url('./WOFF/MDIO0.4-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'MD IO';
    src: url('./WOFF/MDIO0.4-Semibold.woff2') format('woff2'), url('./WOFF/MDIO0.4-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'MD IO';
    src: url('./WOFF/MDIO0.4-Bold.woff2') format('woff2'), url('./WOFF/MDIO0.4-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MD IO';
    src: url('./WOFF/MDIO0.4-Black.woff2') format('woff2'), url('./WOFF/MDIO0.4-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'MD IO';
    src: url('./WOFF/MDIO0.4-Ultra.woff2') format('woff2'), url('./WOFF/MDIO0.4-Ultra.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

.md_io,
.md_io-regular,
.md_io-400 {
    font-family: 'MD IO';
    font-weight: 400;
    font-style: normal;
}

.md_io-italic {
    font-family: 'MD IO';
    font-weight: 400;
    font-style: italic;
}

.md_io-medium,
.md_io-500 {
    font-family: 'MD IO';
    font-weight: 500;
    font-style: normal;
}

.md_io-semibold,
.md_io-600 {
    font-family: 'MD IO';
    font-weight: 600;
    font-style: normal;
}

.md_io-bold,
.md_io-700 {
    font-family: 'MD IO';
    font-weight: 700;
    font-style: normal;
}

.md_io-black,
.md_io-800 {
    font-family: 'MD IO';
    font-weight: 800;
    font-style: normal;
}

.md_io-ultra,
.md_io-900 {
    font-family: 'MD IO';
    font-weight: 900;
    font-style: normal;
}
