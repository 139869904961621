@font-face {
  font-family: MD IO;
  src: url("MDIO0.4-Regular.e38cdfd6.woff2") format("woff2"), url("MDIO0.4-Regular.2bbf59c2.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: MD IO;
  src: url("MDIO0.4-Italic.d0d65d1b.woff2") format("woff2"), url("MDIO0.4-Italic.b28e1da1.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: MD IO;
  src: url("MDIO0.4-Medium.a58333f7.woff2") format("woff2"), url("MDIO0.4-Medium.bddc39f0.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: MD IO;
  src: url("MDIO0.4-Semibold.8dcdbbac.woff2") format("woff2"), url("MDIO0.4-Semibold.b3d73a70.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: MD IO;
  src: url("MDIO0.4-Bold.536162e0.woff2") format("woff2"), url("MDIO0.4-Bold.ffe20da6.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: MD IO;
  src: url("MDIO0.4-Black.35c30e0c.woff2") format("woff2"), url("MDIO0.4-Black.12ad50e0.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: MD IO;
  src: url("MDIO0.4-Ultra.232c35de.woff2") format("woff2"), url("MDIO0.4-Ultra.93853ee5.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

.md_io, .md_io-regular, .md_io-400 {
  font-family: MD IO;
  font-style: normal;
  font-weight: 400;
}

.md_io-italic {
  font-family: MD IO;
  font-style: italic;
  font-weight: 400;
}

.md_io-medium, .md_io-500 {
  font-family: MD IO;
  font-style: normal;
  font-weight: 500;
}

.md_io-semibold, .md_io-600 {
  font-family: MD IO;
  font-style: normal;
  font-weight: 600;
}

.md_io-bold, .md_io-700 {
  font-family: MD IO;
  font-style: normal;
  font-weight: 700;
}

.md_io-black, .md_io-800 {
  font-family: MD IO;
  font-style: normal;
  font-weight: 800;
}

.md_io-ultra, .md_io-900 {
  font-family: MD IO;
  font-style: normal;
  font-weight: 900;
}

/*# sourceMappingURL=merge.e9967abd.css.map */
